import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useLatestValueRef } from '@front/helper';

import { FilterType } from '../../types/filter';
import { SortType } from '../../widgets/CommonPanels/SortPanel/types';

import useIaFilterActions from './useIaFilterActions';
import useIaFilterValues from './useIaFilterValues';
import useIaQueryHelperActions from './useIaQueryHelperActions';
import useIaQueryHelperValues from './useIaQueryHelperValues';
import { useIaSearchActions } from './useIaSearchActions';
import { useIaSearchValues } from './useIaSearchValues';
import { useIaSortActions } from './useIaSortActions';
import useIaSortValues from './useIaSortValues';

/**
 * To use in case there are multiple initialization processes,
 * with process name as key and boolean value representing that process is initializing or completed.
 * The process name should be unique (not required) in whole app
 * to be easier to look up where is the place that marked the process as completed.
 * For example:
 * {
 *    waitUntilInitComplete: {
 *      discoverClub_sortInitializing: true,
 *      discoverClub_filterInitializing: true,
 *    }
 * }
 */
type MultipleInitProcess = Record<string, boolean>;

type Options = {
  scope?: string;
  type?: FilterType | SortType;
  waitUntilInitComplete?: boolean | MultipleInitProcess;
};

/**
 * Should only use this hook to setup the page sort/filter/search.
 * If want to get sort/filter/search values or actions please consider using other hooks:
 * useIaFilterValues/useIaFilterActions, useIaSortValues/useIaSortActions, useIaSearchValues/useIaSearchActions
 */
export default function usePageSearchFilterSort({
  scope = 'global',
  type,
  waitUntilInitComplete = false,
}: Options = {}) {
  const { pathname } = useRouter();
  const waitUntilInitCompleteRef = useLatestValueRef(waitUntilInitComplete);

  const { searchQuery, hasActiveFilter, hasFilter } = useIaFilterValues({
    scope,
  });
  const { resetToDefault: resetFilterToDefault } = useIaFilterActions({
    scope,
  });
  const { sortQuery, rawQueryParams, hasSort } = useIaSortValues({
    scope,
  });
  const { resetToDefault: resetSortToDefault } = useIaSortActions({
    scope,
  });
  const { debouncedSearch } = useIaSearchValues();
  const { resetToDefault: resetSearchToDefault } = useIaSearchActions();
  const { setInitializing, completeInit, completeInitPartial } =
    useIaQueryHelperActions({
      scope,
    });
  const { isInitializing } = useIaQueryHelperValues({
    scope,
  });

  /**
   * When mounted, should set initialization status to values that page required
   * and then when unmounted, should reset it to default value.
   */
  useEffect(() => {
    setInitializing(waitUntilInitCompleteRef.current);
    return () => {
      setInitializing(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * whenever the page is changed, should reset the top bar search to the default value
   */
  useEffect(() => {
    resetSearchToDefault();
    setInitializing(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  /**
   * whenever the type is change, should reset filter and sort to default values of that type.
   * For example:
   * when go to the club page => should reset the RHS filter into `club` to show all filter fields that related to club
   */
  useEffect(() => {
    resetFilterToDefault(type);
    resetSortToDefault(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return {
    keyword: debouncedSearch,
    sort: sortQuery,
    filter: searchQuery,
    hasFilter,
    hasActiveFilter,
    hasSort,
    rawQueryParams,
    isInitializing,
    completeInit,
    completeInitPartial,
  };
}
