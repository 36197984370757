import { useDeferredValue } from 'react';
import combineSort from '@lib/ia/src/utils/combineSort';
import { ClubGroupViewSlug, useIaClubGroups } from '@lib/web/apis';
import { isEqual } from 'lodash';

import usePageSearchFilterSort from '../../hooks/utils/usePageSearchFilterSort';

export const useDiscoverClubGroups = () => {
  const {
    keyword,
    sort,
    filter,
    rawQueryParams,
    isInitializing,
    completeInit,
  } = usePageSearchFilterSort({
    type: 'clubGroup',
    waitUntilInitComplete: true,
  });

  const filterDeferred = useDeferredValue(filter);
  const isFilterStale = !isEqual(filter, filterDeferred);

  const clubGroupsData = useIaClubGroups(
    {
      viewSlug: ClubGroupViewSlug.CardCenterClubGroupDefault,
      ...filterDeferred,
      ...combineSort(sort),
      keywordFuzzy: keyword,
      rawQueryParams,
      limit: 21, // there is 1 'create card' on the top, so instead of 20, we use 21 to make loading looks better
    },
    {
      enable: !isInitializing,
    }
  );
  const { isLoadingInitialData, error } = clubGroupsData;

  return {
    clubGroupsData,
    completeInit,
    isLoading: isFilterStale || isLoadingInitialData,
    isError: !!error,
    isInitializing,
    isLoadingMore: clubGroupsData.isLoadingMore,
    mutate: clubGroupsData.mutate,
    dataset: clubGroupsData.dataset,
    totalCount: clubGroupsData.totalCount,
  };
};
